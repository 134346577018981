
const tableHeaderOptions = {
    api: [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            value: 'createdAt',
        },
        {
            text: 'User',
            align: 'left',
            sortable: true,
            value: 'updatedUserLink.text',
        },
        {
            text: 'Request Id',
            align: 'left',
            sortable: true,
            value: 'requestId',
        },
        {
            text: 'Url',
            align: 'left',
            sortable: true,
            value: 'url',
        },
    ],
    click: [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            value: 'createdAt',
        },
        {
            text: 'User',
            align: 'left',
            sortable: true,
            value: 'updatedUserLink.text',
        },
        {
            text: 'Route',
            align: 'left',
            sortable: true,
            value: 'route',
        },
        {
            text: 'Action',
            align: 'left',
            sortable: true,
            value: 'action',
        },
    ],
    error: [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            value: 'createdAt',
        },
        {
            text: 'User',
            align: 'left',
            sortable: true,
            value: 'updatedUserLink.text',
        },
        {
            text: 'Request Id',
            align: 'left',
            sortable: true,
            value: 'requestId',
        },
        {
            text: 'Operation',
            align: 'left',
            sortable: true,
            value: 'operation',
        },
        {
            text: 'Error',
            align: 'left',
            sortable: true,
            value: 'error',
        },
    ],
    log: [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            value: 'createdAt',
        },
        {
            text: 'User',
            align: 'left',
            sortable: true,
            value: 'updatedUserLink.text',
        },
        {
            text: 'Request Id',
            align: 'left',
            sortable: true,
            value: 'requestId',
        },
        {
            text: 'Operation',
            align: 'left',
            sortable: true,
            value: 'operation',
        },
        {
            text: 'Message',
            align: 'left',
            sortable: true,
            value: 'message',
        },
    ],
    bookmarked: [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            value: 'createdAt',
        },
        {
            text: 'User',
            align: 'left',
            sortable: true,
            value: 'updatedUserLink.text',
        },
        {
            text: 'Request Id',
            align: 'left',
            sortable: true,
            value: 'requestId',
        },
        {
            text: 'Operation',
            align: 'left',
            sortable: true,
            value: 'operation',
        },
        {
            text: 'Url',
            align: 'left',
            sortable: true,
            value: 'url',
        },
        {
            text: 'Route',
            align: 'left',
            sortable: true,
            value: 'route',
        },
        {
            text: 'Action',
            align: 'left',
            sortable: true,
            value: 'action',
        },
        {
            text: 'Error',
            align: 'left',
            sortable: true,
            value: 'errorMessage',
        },
        {
            text: 'Message',
            align: 'left',
            sortable: true,
            value: 'message',
        },
    ],
    all: [
        {
            text: 'Date',
            align: 'left',
            sortable: true,
            value: 'createdAt',
        },
        {
            text: 'User',
            align: 'left',
            sortable: true,
            value: 'updatedUserLink.text',
        },
        {
            text: 'Request Id',
            align: 'left',
            sortable: true,
            value: 'requestId',
        },
        {
            text: 'Operation',
            align: 'left',
            sortable: true,
            value: 'operation',
        },
        {
            text: 'Url',
            align: 'left',
            sortable: true,
            value: 'url',
        },
        {
            text: 'Route',
            align: 'left',
            sortable: true,
            value: 'route',
        },
        {
            text: 'Action',
            align: 'left',
            sortable: true,
            value: 'action',
        },
        {
            text: 'Error',
            align: 'left',
            sortable: true,
            value: 'errorMessage',
        },
        {
            text: 'Message',
            align: 'left',
            sortable: true,
            value: 'message',
        },
    ],
};

export default tableHeaderOptions;
