<template>
    <smart-table
        v-if="tableItems && tableHeaders"
        :headers="tableHeaders"
        :items="tableItems"
        :key="JSON.stringify(tableItems)"
        item-key="id"
        v-model="selected"
        isFilter
        :show-select="!!logBookmarkId"
    >
        <template v-slot:item.requestId="{ item }">
            <btn
                v-if="item.requestId"
                :label="shortRequestId(item.requestId)"
                :clipboardValue="item.requestId"
                preset="clipboard"
            />
        </template>
        <template v-slot:item.createdAt="{ item }">
            <popup
                :triggerButtonLabel="`${dateToString(item.createdAt)} -- ${dateToTime(item.createdAt)}`"
                triggerButtonSize="small"
                trigger-button-preset="popup"
                dialogTitle="Data"
            >
                <template v-slot:buttons>
                    <btn preset="clipboard" :clipboard-value="JSON.stringify(item, null, 2)" />
                </template>

                <vueJsonTree :data="item" />
            </popup>
        </template>
    </smart-table>
</template>

<script>
import VueJsonTree from 'vue-json-tree';
import Popup from '@/components/form/Popup';

import tableHeaderOptions from './tableHeaders';

import dateUtil from '@/../common/utils/date';
import arrayUtil from '@/../common/utils/array';

import * as queryString from 'query-string';
import api from '@/api';

export default {
    components: { VueJsonTree, Popup },
    name: 'LogTable',
    props: {
        tabIndex: Number,
        fromDate: String,
        toDate: String,
        filterUserId: String,
        logType: String,
        logBookmarkId: String,
    },
    data: () => {
        return {
            tableItems: [],
            tableHeaders: [],
            logBookmark: null,
            selected: [],
        };
    },
    methods: {
        dateToTime(date) {
            return dateUtil.dateToTime(date);
        },
        dateToString(date) {
            return dateUtil.dateToString(date);
        },
        shortRequestId(requestId) {
            return requestId.substring(30);
        },
        async getLogs() {
            const query = queryString.stringify({
                tab: this.logType,
                fromDate: this.fromDate,
                toDate: this.toDate,
                userId: this.filterUserId,
                logBookmarkId: this.logBookmarkId,
            });
            const logsResult = await api.get(this.$store, `log?${query}`);

            if (logsResult.data) {
                this.tableItems = logsResult.data;
            }

            if (this.logBookmarkId) {
                const result = await api.get(this.$store, `logBookmark/${this.logBookmarkId}`);

                this.logBookmark = result.data;

                this.selected = this.tableItems.filter((oneTableItem) =>
                    this.logBookmark.logIds.includes(oneTableItem.id),
                );

                console.log('loaded selected', this.selected);
            }
        },
        async tabChange() {
            this.tableHeaders = tableHeaderOptions[this.logType];
            await this.getLogs();
            console.log('tabIndex', this.tabIndex);

            //this.tableHeaders = tableHeaderOptions[this.logTypes[this.tabIndex].title];
            //console.log(this.tableHeaders);
        },
        async updateBookmark() {
            if (this.logBookmark) {
                // creates an array of ids for the selected lines
                const selectedLogIds = this.selected.map((oneSelected) => oneSelected.id);
                console.log('logIds', selectedLogIds);

                // creates an array of ids for all the loaded lines
                const allDisplayedLogIds = this.tableItems.map((oneTableItem) => oneTableItem.id);

                // from the existing bookmarks, checks whether the bookmark is one of the selected OR
                // is not a log displayed in the table. does not want to unset logs that are not in
                // the current table
                const existingValidLogIds = this.logBookmark.logIds.filter(
                    (oneLogId) => selectedLogIds.includes(oneLogId) || !allDisplayedLogIds.includes(oneLogId),
                );

                // gets a list of the newly selected items
                const newLogIds = selectedLogIds.filter((oneLogId) => !existingValidLogIds.includes(oneLogId));

                // concats the valid to the new logs
                const logIds = [...existingValidLogIds, ...newLogIds];

                this.logBookmark.logIds = logIds;

                const result = await api.post(this.$store, 'logBookmark', this.logBookmark);

                if (result.data) this.logBookmark = result.data;
            }
        },
    },
    watch: {
        async selected() {
            await this.updateBookmark();
        },
    },

    async mounted() {
        console.log('log mounted');
    },
    async created() {
        console.log('log created');
        await this.tabChange();
    },
};
</script>

<style scoped></style>
