<template>
    <Page title="Log Inspector" icon="mdi-file-find">
        <smart-form
            v-if="commonFormModel"
            v-model="commonFormModel"
            :schema="commonFormSchema"
            form-code="logInspector-common"
            cols="10"
            :key="filterBookmarksKey"
        >
        </smart-form>
        <confirm ref="confirm" />

        <template v-slot:header>
            <toolbar :left-items="[{ preset: 'search', onClick: getLogs }]">
                <btn v-if="commonFormModel.logBookmarkId" preset="delete" @click="clickDelete" />
                <popup
                    v-else
                    :triggerButtonLabel="`add bookmark`"
                    triggerButtonSize="small"
                    trigger-button-preset="add"
                    dialogTitle="Add Bookmark"
                    :dialogActionFunc="addBookmark"
                    :value="popupModel"
                    :formSchema="popupFormSchema"
                />
            </toolbar>
        </template>

        <v-tabs v-if="logTypes" :key="JSON.stringify(logTypes)" v-model="tabIndex">
            <v-tab
                v-for="oneLogType in logTypes"
                :key="oneLogType"
                :style="`background-color: #80808020; `"
                :value="oneLogType"
                class="px-7"
            >
                {{ oneLogType }}
            </v-tab>
        </v-tabs>
        <LogTable
            :key="`${tabIndex} ${logTableKey} ${commonFormModel.logBookmarkId}`"
            :tabIndex="tabIndex"
            :fromDate="commonFormModel.dateRange && commonFormModel.dateRange[0]"
            :toDate="commonFormModel.dateRange && commonFormModel.dateRange[0]"
            :filterUserId="commonFormModel.filterUser"
            :logType="logTypes[tabIndex]"
            :logBookmarkId="commonFormModel.logBookmarkId"
        />
    </Page>
</template>

<script>
import LogTable from './LogTable';
import Popup from '@/components/form/Popup';

const commonFormSchema = {
    dateRange: {
        component: 'date-field',
        label: 'Date',
        cols: 2,
        readOnly: false,
        range: true,
        today: true,
    },
    filterUser: {
        component: 'text-field',
        label: 'User',
        cols: 2,
        autocomplete: {
            type: 'user',
        },
        clearable: true,
        returnObject: false,
    },
    logBookmarkId: {
        component: 'text-field',
        label: 'Bookmark',
        cols: 2,
        autocomplete: {
            type: 'logBookmark',
        },
        clearable: true,
        returnObject: false,
    },
};

const popupFormSchema = {
    bookmarkName: {
        component: 'text-field',
        label: 'Bookmark Name',
        cols: 12,
    },
};
import api from '@/api';

export default {
    name: 'LogInspector',
    components: { LogTable, Popup },
    data() {
        return {
            commonFormSchema,
            popupFormSchema,
            commonFormModel: { logBookmarkId: null },

            popupModel: {},
            popupDialog: false,
            dataDialog: false,
            moreData: {},

            logTypes: ['api', 'click', 'error', 'log', 'all', 'bookmarked'],
            tabIndex: 0,
            logTableKey: 0,
            filterBookmarksKey: 0,
        };
    },
    methods: {
        getLogs() {
            this.logTableKey++;
        },
        async clickDelete() {
            if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
                const result = await api.delete(this.$store, `logbookmark/${this.commonFormModel.logBookmarkId}`);
                this.commonFormModel.logBookmarkId = '';
                this.filterBookmarksKey++;
            }
        },
        async addBookmark() {
            if (this.popupModel.bookmarkName.length >= 4) {
                const bookmarkPostResult = await api.post(this.$store, `logbookmark`, {
                    bookmarkName: this.popupModel.bookmarkName,
                });

                if (bookmarkPostResult.data) this.commonFormModel.logBookmarkId = bookmarkPostResult.data.id;

                this.popupModel.bookmarkName = '';

                this.filterBookmarksKey++;
            }
        },
    },
};
</script>

<style scoped></style>
