import objectUtil from './object';

const difference = (arr1, arr2) => arr1.filter((item) => !arr2.includes(item));

const intersection = (arr1, arr2) => arr1.filter((item) => arr2.includes(item));

const getUniqueArrayOfObjectsByObjectKey = (data, uniqueKey, returnKey) => {
    // generates an array of unique objects using an object key to establish uniqueness

    const keySet = new Set(data.map((oneRecord) => objectUtil.getObjectFieldByString(uniqueKey, oneRecord)));

    const keyArray = Array.from(keySet);

    if (returnKey) {
        const returnArray = keyArray.map((oneRecord) =>
            keyArray.find(
                (oneEl) =>
                    objectUtil.getObjectFieldByString(uniqueKey, oneEl) ===
                    objectUtil.getObjectFieldByString(uniqueKey, oneRecord),
            ),
        );


        return returnArray;
    } else {
        return keyArray;
    }
};

const getUniqueArrayOfObjectsByObjectKeys = (data, uniqueKeys) => {
    return data.reduce((accum, oneItem) => {
        const newItemValues = uniqueKeys.reduce((accum, oneKey) => {
            const returnValue = { ...accum, [oneKey]: oneItem[oneKey] };

            return returnValue;
        }, {});

        const uniqueList = [...accum];

        const matchedItemValues = uniqueList.find((oneItem) => {
            return uniqueKeys.reduce((accum, oneKey) => {
                return accum && oneItem[oneKey] === newItemValues[oneKey];
            }, true);
        })

        if (!matchedItemValues) {
            uniqueList.push(newItemValues);
        }
        return uniqueList;

    }, []);
};

const getUniqueArrayOfObjects = (data, uniqueKey, returnKey) => {
    if (Array.isArray(uniqueKey)) {
        return getUniqueArrayOfObjectsByObjectKeys(data, uniqueKey);
    }
    return getUniqueArrayOfObjectsByObjectKey(data, uniqueKey, returnKey);
};


const symmetricalDifference = (arr1, arr2) =>
    arr1.filter((item) => !arr2.includes(item)).concat(arr2.filter((item) => !arr1.includes(item)));

const union = (arr1, arr2) => [...new Set([...arr1, ...arr2])];

const removeAtIndex = (arr, index) => {
    const copy = [...arr];
    copy.splice(index, 1);
    return copy;
};

const toggle = (arr, item, getValue = item => item) => {
    const index = arr.findIndex(i => getValue(i) === getValue(item));
    if (index === -1) return [...arr, item];
    return removeAtIndex(arr, index);
};

export default {
    intersection,
    difference,
    getUniqueArrayOfObjects,
    symmetricalDifference,
    union,
    toggle

};
